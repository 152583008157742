<template>
  <div class="main clearfix">
    <!-- <div class="m_nav">
      <div class="m_nav_box">
        
          <ul class="nav-box">
              <li><router-link to="/news/company">公司新闻</router-link></li>
              <li><router-link to="/news/publish">通知公告</router-link></li>
          </ul>
      </div>
    </div> -->
    <CurrentLocation />
    <div class="box">
      <div class="ct2-sd">
      <PanelNav :title="title" :navs="nav" />
      <PanelLast />
      </div>
    <div class="ct2-mn">
      <PanelList
        v-for="(item, index) in navs"
        :key="index"
        :data-index="index"
        :title="item.title"
        :router="item.router"
        :more="false"
        :data="item.data"
      
      />
    </div>
    </div>
    
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
// import PanelLast from "../layout/components/PanelLast";
import PanelList from "../layout/components/PanelList";

import axios from "@/api/apis";

export default {
  name: "News",
  components: { CurrentLocation, PanelNav, PanelList },
  created() {},
  setup() {
    const state = reactive({
      title: "公司建设",
      navs: [
        {
          title: "公司新闻",
          router: "/news/company",
          data: {
            list: [],
          },
        },
        
      ],
      nav: [
        {
          title: "公司新闻",
          router: "/news/company",
          data: {
            list: [],
          },
        },
        {
          title: "通知公告",
          router: "/news/publish",
          data: {
            list: [],
          },
        },
        {
          title: "党风建设",
          router: "/party",
          data: {
            list: [],
          },
        },
      ]
    });
    const loadArticle = () => {
      axios
        .post("https://cqgdrm.com/api/website/article/page", { group: 1, pageSize: 5, current: 1 })
        .then(function (data) {
          data.data.forEach((item) => {
            var image=''
            if(item.images!= null){
            image=item.images[0]
          }else{
            image=''
          }
            state.navs[0].data.list.push({
              id: item.id,
              title: item.title,
              type: item.type,
              abstracts:item.abstracts,
              content: item.content,
              images:image,
              publishTime: item.publishTime,
              router: "/news/company/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const loadPublish = () => {
      axios
        .post("https://cqgdrm.com/api/website/publish/page", {
          pageSize: 5, current: 1
        })
        .then(function (data) {
          data.data.forEach((item) => {
            state.navs[1].data.list.push({
              id: item.id,
              title: item.title,
              type: 1,
              content: item.content,
              publishTime: item.createdTime,
              router: "/news/publish/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadArticle();
      loadPublish();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>
